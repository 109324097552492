import React from 'react';
import {
    AutocompleteInput,
    useGetList,
    required
  } from "react-admin";
import entity from '@src/entityResource';

/**
 * @description Grade component. Gr stand for Grade
 * @param formData object from FormDataConsumer
 */
const Gr = ({formData, isRequired, source}: {formData: any; isRequired: boolean; source: string}) => {
    const { data: grades } = useGetList(entity.GRADE.NAME, {filter: { stageId: formData.stageId }});

    return (
      <React.Fragment>
        <AutocompleteInput
          source={source}
          choices={grades}
          optionText={(grade) => `${grade.alphanumeric}.${grade.sub} - ${grade.name}`}
          optionValue="id"
          fullWidth
          validate={isRequired ? required() : undefined}
        />
      </React.Fragment>
    );
}

const Grade = ({formData, isRequired, source}: {formData: any; isRequired: boolean; source: string}) => <Gr source={source} formData={formData} isRequired={isRequired}  />
export default Grade;
