import React from 'react';
const RichTextInput = React.lazy(() =>
  import('ra-input-rich-text').then(module => ({
      default: module.RichTextInput,
  }))
);
import {
  DateInput,
  FileInput,
  FileField,
  ImageInput,
  ImageField,
  TextInput,
  useTranslate
} from "react-admin";
import { Box, Grid, Typography } from "@mui/material";
import SodikSimpleForm from "@src/component/SodikSimpleForm";
import RewardType from '@src/component/RewardType';
import {
  boxContainerDisplay,
  boxContainerSx,
  boxContainerFieldsetSx,
  boxItemMr,
} from "@src/themes/componentStyle";
import { parse, format } from "@src/helper/number";

const SchoolScheduleForm = () => {
  const translate = useTranslate();
  
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <SodikSimpleForm>
          
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
            <Box display={boxContainerDisplay} sx={boxContainerFieldsetSx}>
                <Box flex={1} mr={boxItemMr}>
                  <RewardType isRequired={true} />
                </Box>
              </Box>

              <Box display={boxContainerDisplay} sx={boxContainerFieldsetSx}>
                <Box flex={1} mr={boxItemMr}>
                  <TextInput source="phoneNo" fullWidth required />
                </Box>
              </Box>

              <Box display={boxContainerDisplay} sx={boxContainerFieldsetSx}>
                <Box flex={1} mr={boxItemMr}>
                  <TextInput source="name" fullWidth required />
                </Box>
              </Box>

              <Box display={boxContainerDisplay} sx={boxContainerFieldsetSx}>
                <Box flex={1} mr={boxItemMr}>
                  <TextInput source="point" fullWidth required format={format} parse={parse} defaultValue={0}/>
                </Box>
                <Box flex={2} mr={boxItemMr}>
                  <TextInput source="value" fullWidth required format={format} parse={parse} defaultValue={0}/>
                </Box>
                <Box flex={1} mr={boxItemMr}>
                  <TextInput source="limit" fullWidth required format={format} parse={parse} defaultValue={0}/>
                </Box>
              </Box>

              <Box display={boxContainerDisplay} sx={boxContainerFieldsetSx}>
                <Box flex={1} mr={boxItemMr}>
                  <DateInput
                    source="validFrom"
                    fullWidth
                  />
                </Box>
                <Box flex={1} mr={boxItemMr}>
                  <DateInput source="validTo" fullWidth />
                </Box>
              </Box>

              <Box display={boxContainerDisplay} sx={boxContainerFieldsetSx}>
                <Box flex={1} mr={boxItemMr}>
                  <TextInput source="map" fullWidth />
                </Box>
              </Box>
              
            </Box>

            <Box flex={1} mr={boxItemMr}>
              <Box display={boxContainerDisplay} sx={boxContainerFieldsetSx}>
                <Box flex={1} mr={boxItemMr}>
                  <RichTextInput source="description" />
                </Box>
              </Box>

              <Box display={boxContainerDisplay} sx={boxContainerFieldsetSx}>
                <Box flex={1} mr={boxItemMr}>
                  <FileInput source="pdf" accept="application/pdf">
                      <FileField source="src" title="title" />
                  </FileInput>
                </Box>
              </Box>
              <Box display={boxContainerDisplay} sx={boxContainerFieldsetSx}>
                <Box flex={1} mr={boxItemMr}>
                  <Typography align="center" variant="subtitle1" gutterBottom>
                    <strong>{translate("amip.common.or")}</strong>
                  </Typography>
                </Box>
              </Box>
              <Box display={boxContainerDisplay} sx={boxContainerFieldsetSx}>
                <Box flex={1} mr={boxItemMr}>
                  <ImageInput source="file" accept="image/*" maxSize={500000}>
                    <ImageField source="src" title="title" />
                  </ImageInput>
                </Box>
              </Box>
            </Box>
          </Box>
        </SodikSimpleForm>
      </Grid>
    </Grid>
  );
};

export default SchoolScheduleForm;
