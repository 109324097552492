import React from "react";
import {
  useTranslate,
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  useSidebarState,
  usePermissions
} from "react-admin";
import { useLocation } from 'react-router-dom';
import Box from "@mui/material/Box";
import DatasetIcon from "@mui/icons-material/Dataset";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import ReceiptIcon from '@mui/icons-material/Receipt';
import TuneIcon from '@mui/icons-material/Tune';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import MoneyIcon from '@mui/icons-material/Money';
import SubMenu from "./SubMenu";
import stage from "@src/stage";
import grade from "@src/grade";
import schoolYear from "@src/school-year";
import student from "@src/student";
import employee from "@src/employee";
import invoiceType from "@src/invoice-type";
import schoolYearStudent from "@src/school-year-student";
import schoolYearInvoice from "@src/school-year-invoice";
import schoolYearEmployee from "@src/school-year-employee";
import highlightType from "@src/highlight-type";
import schoolHighlight from "@src/school-highlight";
import schoolSchedule from "@src/school-schedule";
import schoolCalendar from "@src/school-calendar";
import schoolGuideline from "@src/school-guideline";
import noteType from "@src/note-type";
import schoolYearStudentNote from "@src/school-year-student-note";
import user from "@src/user";
import role from "@src/role";
import rewardType from "@src/reward-type";
import reward from "@src/reward";
import saving from "@src/saving";
import entity from "@src/entityResource";

type MenuName = "schoolMenu" | "educationMenu" | "savingMenu" | "invoiceMenu" | "adminMenu" | "rewardMenu";

const Menu = ({ dense = false }: MenuProps) => {
  const location = useLocation();
  const translate = useTranslate();
  const [open] = useSidebarState();
  const {permissions} = usePermissions();
  const schoolPath = ['/stages', '/grades', '/school-years', '/employees', '/students', '/school-guidelines'];
  const educationPath = permissions && permissions.toLowerCase().includes("admin")
    ? ['/school-calendars', '/school-year-employees', '/highlight-types', '/school-highlights', '/school-schedules', '/note-types', '/school-year-student-notes']
    : ['/school-calendars', '/school-year-employees', '/school-year-students', '/highlight-types', '/school-highlights', '/school-schedules', '/note-types', '/school-year-student-notes'];
  const invoicePath = ['/invoice-types', '/school-year-invoices', '/school-year-students'];
  const savingPath = ['/savings'];
  const rewardPath = ['/reward-types', '/rewards'];
  const adminPath = ['/roles', '/users'];

  const [state, setState] = React.useState({
    schoolMenu: false,
    educationMenu: false,
    invoiceMenu: false,
    savingMenu: false,
    adminMenu: false,
    rewardMenu: false,
  });

  React.useEffect(() => {
    setState({
      schoolMenu: schoolPath.some(path => location.pathname.startsWith(path)),
      educationMenu: educationPath.some(path => location.pathname.startsWith(path)),
      invoiceMenu: invoicePath.some(path => location.pathname.startsWith(path)),
      savingMenu: savingPath.some(path => location.pathname.startsWith(path)),
      adminMenu: adminPath.some(path => location.pathname.startsWith(path)),
      rewardMenu: rewardPath.some(path => location.pathname.startsWith(path)),
    });
  }, [location]);
  
  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Box
      sx={{
        width: open ? 300 : 75,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <DashboardMenuItem />
      {
        permissions && permissions.toLowerCase().includes("admin") && 
        <>
          <SubMenu
            handleToggle={() => handleToggle("schoolMenu")}
            isOpen={state.schoolMenu}
            name="amip.menu.data"
            icon={<DatasetIcon />}
            dense={dense}
          >
            <MenuItemLink
              to={entity.STAGE.NAME}
              state={{ _scrollToTop: true }}
              primaryText={translate(`resources.stages.name`, {
                smart_count: 2,
              })}
              leftIcon={<stage.icon />}
              dense={dense}
            />
            <MenuItemLink
              to={entity.GRADE.NAME}
              state={{ _scrollToTop: true }}
              primaryText={translate(`resources.grades.name`, {
                smart_count: 2,
              })}
              leftIcon={<grade.icon />}
              dense={dense}
            />
            <MenuItemLink
              to={entity.SCHOOL_YEAR.NAME}
              state={{ _scrollToTop: true }}
              primaryText={translate(`resources.school-years.name`, {
                smart_count: 2,
              })}
              leftIcon={<schoolYear.icon />}
              dense={dense}
            />
            <MenuItemLink
              to={entity.EMPLOYEE.NAME}
              state={{ _scrollToTop: true }}
              primaryText={translate(`resources.employees.name`, {
                smart_count: 2,
              })}
              leftIcon={<employee.icon />}
              dense={dense}
            />
            <MenuItemLink
              to={entity.STUDENT.NAME}
              state={{ _scrollToTop: true }}
              primaryText={translate(`resources.students.name`, {
                smart_count: 2,
              })}
              leftIcon={<student.icon />}
              dense={dense}
            />
            <MenuItemLink
              to={entity.SCHOOL_GUIDELINE.NAME}
              state={{ _scrollToTop: true }}
              primaryText={translate(`resources.school-guidelines.name`, {
                smart_count: 2,
              })}
              leftIcon={<schoolGuideline.icon />}
              dense={dense}
            />
          </SubMenu>
          <SubMenu
            handleToggle={() => handleToggle("invoiceMenu")}
            isOpen={state.invoiceMenu}
            name="amip.menu.invoice"
            icon={<ReceiptIcon />}
            dense={dense}
          >
            <MenuItemLink
              to={entity.INVOICE_TYPE.NAME}
              state={{ _scrollToTop: true }}
              primaryText={translate(`resources.invoice-types.name`, {
                smart_count: 2,
              })}
              leftIcon={<invoiceType.icon />}
              dense={dense}
            />
            <MenuItemLink
              to={entity.SCHOOL_YEAR_STUDENT.NAME}
              state={{ _scrollToTop: true }}
              primaryText={translate(`resources.school-year-students.name`, {
                smart_count: 2,
              })}
              leftIcon={<schoolYearStudent.icon />}
              dense={dense}
            />
            <MenuItemLink
              to={entity.SCHOOL_YEAR_INVOICE.NAME}
              state={{ _scrollToTop: true }}
              primaryText={translate(`resources.school-year-invoices.name`, {
                smart_count: 2,
              })}
              leftIcon={<schoolYearInvoice.icon />}
              dense={dense}
            />
          </SubMenu>
          <SubMenu
            handleToggle={() => handleToggle("rewardMenu")}
            isOpen={state.rewardMenu}
            name="amip.menu.reward"
            icon={<LocalMallIcon />}
            dense={dense}
          >
            <MenuItemLink
              to={entity.REWARD_TYPE.NAME}
              state={{ _scrollToTop: true }}
              primaryText={translate(`resources.reward-types.name`, {
                smart_count: 2,
              })}
              leftIcon={<rewardType.icon />}
              dense={dense}
            />
            <MenuItemLink
              to={entity.REWARD.NAME}
              state={{ _scrollToTop: true }}
              primaryText={translate(`resources.rewards.name`, {
                smart_count: 2,
              })}
              leftIcon={<reward.icon />}
              dense={dense}
            />
          </SubMenu>
          <SubMenu
            handleToggle={() => handleToggle("savingMenu")}
            isOpen={state.savingMenu}
            name="amip.menu.saving"
            icon={<MoneyIcon />}
            dense={dense}
          >
            <MenuItemLink
              to={entity.SAVING.NAME}
              state={{ _scrollToTop: true }}
              primaryText={translate(`resources.savings.name`, {
                smart_count: 2,
              })}
              leftIcon={<saving.icon />}
              dense={dense}
            />
          </SubMenu>
        </>
      }
      <SubMenu
        handleToggle={() => handleToggle("educationMenu")}
        isOpen={state.educationMenu}
        name="amip.menu.education"
        icon={<HistoryEduIcon />}
        dense={dense}
      >
        <MenuItemLink
          to={entity.SCHOOL_CALENDAR.NAME}
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.school-calendars.name`, {
            smart_count: 2,
          })}
          leftIcon={<schoolCalendar.icon />}
          dense={dense}
        />
        <MenuItemLink
          to={entity.SCHOOL_YEAR_EMPLOYEE.NAME}
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.school-year-employees.name`, {
            smart_count: 2,
          })}
          leftIcon={<schoolYearEmployee.icon />}
          dense={dense}
        />
        { 
          permissions && !permissions.toLowerCase().includes("admin") &&
          <MenuItemLink
            to={entity.SCHOOL_YEAR_STUDENT.NAME}
            state={{ _scrollToTop: true }}
            primaryText={translate(`resources.school-year-students.name`, {
              smart_count: 2,
            })}
            leftIcon={<schoolYearStudent.icon />}
            dense={dense}
          />
        }
        <MenuItemLink
          to={entity.HIGHLIGHT_TYPE.NAME}
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.highlight-types.name`, {
            smart_count: 2,
          })}
          leftIcon={<highlightType.icon />}
          dense={dense}
        />
        <MenuItemLink
          to={entity.SCHOOL_HIGHLIGHT.NAME}
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.school-highlights.name`, {
            smart_count: 2,
          })}
          leftIcon={<schoolHighlight.icon />}
          dense={dense}
        />
        <MenuItemLink
          to={entity.SCHOOL_SCHEDULE.NAME}
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.school-schedules.name`, {
            smart_count: 2,
          })}
          leftIcon={<schoolSchedule.icon />}
          dense={dense}
        />
        <MenuItemLink
          to={entity.NOTE_TYPE.NAME}
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.note-types.name`, {
            smart_count: 2,
          })}
          leftIcon={<noteType.icon />}
          dense={dense}
        />
        <MenuItemLink
          to={entity.SCHOOL_YEAR_STUDENT_NOTE.NAME}
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.school-year-student-notes.name`, {
            smart_count: 2,
          })}
          leftIcon={<schoolYearStudentNote.icon />}
          dense={dense}
        />
      </SubMenu>
      {
        permissions && permissions.toLowerCase().includes("admin") &&
        <SubMenu
          handleToggle={() => handleToggle("adminMenu")}
          isOpen={state.adminMenu}
          name="amip.menu.admin"
          icon={<TuneIcon />}
          dense={dense}
        >
          <MenuItemLink
            to={entity.ROLE.NAME}
            state={{ _scrollToTop: true }}
            primaryText={translate(`resources.roles.name`, {
              smart_count: 2,
            })}
            leftIcon={<role.icon />}
            dense={dense}
          />
          <MenuItemLink
            to={entity.USER.NAME}
            state={{ _scrollToTop: true }}
            primaryText={translate(`resources.users.name`, {
              smart_count: 2,
            })}
            leftIcon={<user.icon />}
            dense={dense}
          />
        </SubMenu>
      }
    </Box>
  );
};

export default Menu;
