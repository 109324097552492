import React from "react";
import { 
  Datagrid,
  FormDataConsumer,
  FunctionField,
  ListContextProvider,
  TextField,
  TextInput,
  useGetOne,
  useGetList,
  useGetRecordId,
  useList,
  useTranslate 
} from "react-admin";
import {TextField as MUITextField} from '@mui/material';
import SodikInfiniteList from "@src/component/SodikInfiniteList";
import SodikSimpleForm from "@src/component/SodikSimpleForm";
import Student from "@src/component/Student";
import { Box, Grid } from "@mui/material";
import {
  boxContainerDisplay,
  boxContainerSx,
  boxItemMr,
  hideWhiteSpace
} from "@src/themes/componentStyle";
import entity from "@src/entityResource";
import { parse, format } from "@src/helper/number";
import { longDisplayFormatWithTime } from "@src/helper/dateFormat";
import env from "@src/environment";

const checkRecordId = () => {
  try {
    const recordId = useGetRecordId();
    return Number(recordId);
  } catch (error) {
    return null;
  }
}

const StatementList = ({savingId} : {savingId: number}) => {
  const intl = env.localFormat;
  const { data, isLoading } = useGetList(entity.SAVING_STATEMENTS.NAME, {filter: { savingId } });
  const listContext = useList({data, isLoading})

  return (
    <ListContextProvider value={listContext}>
      <Datagrid bulkActionButtons={false}>
        <FunctionField
          source="createdAt"
          render={(record: any) =>
            longDisplayFormatWithTime(record.createdAt, "id-ID")
          }
        />
        <TextField source="savingType" />
        <TextField source="description" />
        <FunctionField
          source="previousBalance"
          textAlign="right"
          render={(record: any) =>
            Intl.NumberFormat(intl).format(record.previousBalance)
          }
        />
        <FunctionField
          source="amount"
          textAlign="right"
          render={(record: any) =>
            Intl.NumberFormat(intl).format(record.amount)
          }
        />
        <FunctionField
          source="balance"
          textAlign="right"
          render={(record: any) =>
            Intl.NumberFormat(intl).format(record.balance)
          }
        />
      </Datagrid>
    </ListContextProvider>
  );
}

const SavingForm = () => {
  const recordId = checkRecordId();
  const translate = useTranslate();

  const nis = translate("resources.savings.fields.nis");
  const grade = translate("resources.savings.fields.grade");
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <SodikSimpleForm isDelete={false}>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            { !recordId && 
              <>
                <Box flex={1} mr={boxItemMr}><Student isRequired={true} path={entity.STUDENT.UNSAVING} /></Box> 
                <Box flex={1} mr={boxItemMr}><TextInput source="initialBalance" parse={parse} format={format} defaultValue={0} fullWidth /></Box>
              </>
            }
            { 
              recordId &&  
              <>
                <Box flex={1} mr={boxItemMr}>
                  <TextInput source="student.name" fullWidth InputProps={{readOnly: true}} />
                </Box>
                <Box flex={1} mr={boxItemMr}>
                  <TextInput source="balance" parse={parse} format={format} defaultValue={0} fullWidth InputProps={{readOnly: true}} />
                </Box>
              </>              
            }
          </Box>
          <FormDataConsumer>
            {({ formData }) => {
              const {data: student} = useGetOne(entity.STUDENT.NAME, {id: formData?.studentId ?? 0});
              const {data: schoolYearStudent} = useGetOne(entity.SCHOOL_YEAR_STUDENT.STUDENT, {id: formData?.studentId ?? 0});
              return(
                <React.Fragment>
                  <Box display={boxContainerDisplay} sx={boxContainerSx}>
                    <Box flex={1} mr={boxItemMr}>
                      {student?.nis && <MUITextField label={nis} variant="filled" value={student.nis} fullWidth InputProps={{readOnly: true}} />}
                    </Box>
                    <Box display={hideWhiteSpace} flex={1} mr={boxItemMr}>
                      {schoolYearStudent?.grade && <MUITextField label={grade} variant="filled" value={`${schoolYearStudent.grade.name} - ${schoolYearStudent.grade.alphanumeric}.${schoolYearStudent.grade.sub}`} fullWidth InputProps={{readOnly: true}} />}
                    </Box>
                  </Box>
                </React.Fragment>
              )
            }}
          </FormDataConsumer>
          {
            recordId && 
            <Box marginTop={4} display={boxContainerDisplay} sx={boxContainerSx}>
              <Box flex={1}>
                <StatementList savingId={recordId} />
              </Box>
            </Box>
          }
        </SodikSimpleForm>
      </Grid>
    </Grid>
  );
};

export default SavingForm;
