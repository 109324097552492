import React from "react";
const RichTextInput = React.lazy(() =>
  import('ra-input-rich-text').then(module => ({
      default: module.RichTextInput,
  }))
);
import {
  FormDataConsumer,
  DateInput,
  FileInput,
  FileField,
  ImageInput,
  ImageField,
  useTranslate
} from "react-admin";
import { Box, Grid, Typography } from "@mui/material";
import SodikSimpleForm from "@src/component/SodikSimpleForm";
import Stage from "@src/component/Stage";
import Grade from "@src/component/Grade";
import {
  boxContainerDisplay,
  boxContainerSx,
  boxContainerFieldsetSx,
  boxItemMr,
} from "@src/themes/componentStyle";

const SchoolScheduleForm = () => {
  const translate = useTranslate();
  
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <SodikSimpleForm>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <Stage source="stageId" isRequired={true} />
            </Box>
            <Box flex={1} mr={boxItemMr}>
              <FormDataConsumer>
                {({ formData }) => <Grade source="gradeId" formData={formData} isRequired={true} />}
              </FormDataConsumer>
            </Box>
          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <FormDataConsumer>
                {({ formData }) => {
                  const isFilled = Boolean(formData.scheduleDate || (formData.startDate && formData.endDate));
                  return (
                    <React.Fragment>
                      <Box display={boxContainerDisplay} sx={boxContainerFieldsetSx}>
                        <Box flex={1} mr={boxItemMr}>
                          <DateInput
                            source="scheduleDate"
                            required={!isFilled}
                            fullWidth
                          />
                        </Box>
                      </Box>
                      <Box display={boxContainerDisplay} sx={boxContainerFieldsetSx}>
                        <Box flex={1} mr={boxItemMr}>
                          <Typography align="center" variant="subtitle1" gutterBottom>
                            <strong>{translate("amip.common.or")}</strong>
                          </Typography>
                        </Box>
                      </Box>
                      <Box display={boxContainerDisplay} sx={boxContainerFieldsetSx}>
                        <Box flex={1} mr={boxItemMr}>
                          <DateInput
                            source="startDate"
                            required={!isFilled}
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} mr={boxItemMr}>
                          <DateInput
                            source="endDate"
                            required={!isFilled}
                            fullWidth
                          />
                        </Box>
                      </Box>
                    </React.Fragment>
                  );
                }}
              </FormDataConsumer>
              <Box display={boxContainerDisplay} sx={boxContainerFieldsetSx}>
                <Box flex={1} mr={boxItemMr}>
                  <RichTextInput source='description' />
                </Box>
              </Box>
            </Box>

            <Box flex={1} mr={boxItemMr}>
              <Box display={boxContainerDisplay} sx={boxContainerFieldsetSx}>
                <FileInput source="pdf" accept="application/pdf">
                  <FileField source="src" title="title" />
                </FileInput>
              </Box>
              <Box display={boxContainerDisplay} sx={boxContainerFieldsetSx}>
                <Box flex={1} mr={boxItemMr}>
                  <Typography align="center" variant="subtitle1" gutterBottom>
                    <strong>{translate("amip.common.or")}</strong>
                  </Typography>
                </Box>
              </Box>
              <Box display={boxContainerDisplay} sx={boxContainerFieldsetSx}>
                <Box flex={1} mr={boxItemMr}>
                  <ImageInput source="file" accept="image/*" maxSize={500000}>
                    <ImageField source="src" title="title" />
                  </ImageInput>
                </Box>
              </Box>
            </Box>
          </Box>
        </SodikSimpleForm>
      </Grid>
    </Grid>
  );
};

export default SchoolScheduleForm;
