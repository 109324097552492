import React from "react";
import {
  SimpleFormIterator,
  FormDataConsumer,
  AutocompleteInput,
  AutocompleteInputProps,
  BooleanInput,
  ArrayInput,
  TextInput,
  RaRecord,
  required,
  useGetRecordId,
  useGetList,
  useGetOne,
  usePermissions
} from "react-admin";
import { Identifier } from 'ra-core';
import { useFormContext } from 'react-hook-form';
import { Box, Grid } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import SodikSimpleForm from "@src/component/SodikSimpleForm";
import SchoolYear from "@src/component/SchoolYear";
import Stage from "@src/component/Stage";
import Grade from "@src/component/Grade";
import Student from "@src/component/Student";
import {
  boxContainerDisplay,
  boxContainerSx,
  boxItemMr,
  hideWhiteSpace
} from "@src/themes/componentStyle";
import { parse, format } from "@src/helper/number";
import env from "@src/environment";
import entity from "@src/entityResource";

/**
 * @description Check record id to differentiate between create and edit
 * @returns 
 */
const checkRecordId = () => {
  try {
    const recordId = useGetRecordId();
    return Number(recordId);
  } catch (error) {
    return null;
  }
}

const generateMonthYear = (startDate: string, endDate: string, current?: string[]): {id: string, name: string}[] => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const result: {id: string, name: string}[] = [];

  for (let dt = start; dt <= end; dt.setMonth(dt.getMonth() + 1)) {
    const monthName = dt.toLocaleString(env.localFormat, { month: 'short' });
    const year = dt.getFullYear();
    const id = `${monthName} ${year}`;
    const name = `${monthName} ${year}`;
    result.push({id, name});
  }

  return result.filter((it) => !current?.includes(it.id));
}

/**
 * @description Get invoice balance from amount and payment
 * @param amount Invoice amount
 * @param payment Invoice payment
 * @returns 
 */
const getBalance = (amount: number, payment: number) => {
  return amount - payment;
}

const InvoiceType = ({ formData, scopedFormData, getSource }: { formData: any, scopedFormData: any, getSource: any }) => {
  const { setValue } = useFormContext();
  const { data: invoiceTypes } = useGetList(entity.INVOICE_TYPE.NAME, {
    filter: { stageId: formData.stageId },
  });

  const handleChange: AutocompleteInputProps['onChange'] = (value, record) => {
    const amount = (record as RaRecord<Identifier>)?.amount;
    setValue(getSource('invoiceAmount'), amount);
    setValue(getSource("invoiceBalance"), amount);
  };

  return (
    <AutocompleteInput
      label="resources.school-year-students.fields.invoiceTypeId"
      sx={{ flex: 2, mr: { xs: 0, sm: "0.5em" } }}
      source={getSource("invoiceTypeId")}
      choices={invoiceTypes}
      optionText="name"
      optionValue="id"
      validate={required()}
      onChange={handleChange}
    />
  )
}

const SchoolYearStudentForm = () => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const recordId = checkRecordId();
  const {permissions} = usePermissions();
  const validateInvoiceType = (isMonthly: boolean) => isMonthly ? required() : undefined;
  
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <SodikSimpleForm>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <SchoolYear />
            </Box>
            <Box
              flex={1}
              mr={boxItemMr}
            >
              <Stage source="stageId" isRequired={true} />
            </Box>
          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
              <FormDataConsumer>
                {({ formData }) => {
                  return(
                    <React.Fragment>
                      <Box flex={1} mr={boxItemMr}>
                        <Grade source="gradeId" formData={formData} isRequired={true} />
                      </Box>
                      <Box flex={1} mr={boxItemMr}>
                        {
                          recordId ? (
                            <TextInput
                              source="student.name"
                              fullWidth
                            />
                          ) : (
                            <FormDataConsumer>
                              {({ formData }) => <Student schoolYearId={+formData.schoolYearId} isRequired={true} path={entity.STUDENT.UNREGISTER} /> }
                            </FormDataConsumer>
                          )
                        }
                      </Box>
                    </React.Fragment>
                  )
                } }
              </FormDataConsumer>
          </Box>
          <Box display={boxContainerDisplay} sx={boxContainerSx}>
            <Box flex={1} mr={boxItemMr}>
              <BooleanInput source="isBlacklisted" validate={required()} />
            </Box>
            <Box flex={1} display={hideWhiteSpace} mr={boxItemMr}>&nbsp;</Box>
          </Box>
          { 
            permissions && permissions.toLowerCase().includes("admin") && 
            <Box display={boxContainerDisplay} sx={boxContainerSx}>
              <ArrayInput source="schoolYearInvoices">
                <SimpleFormIterator
                  inline={isSmall ? false : true}
                  disableReordering
                  disableClear
                  fullWidth
                >
                  <FormDataConsumer>
                    {({ formData, scopedFormData, getSource }) => {
                      const { data: invoiceTypes } = useGetList(entity.INVOICE_TYPE.NAME, {
                        filter: { stageId: formData.stageId },
                      });
                      const invoicePeriodeExists = formData?.schoolYearInvoices?.map((it: any) => it.invoicePeriode).filter(Boolean);
                      const invoiceTypeId = scopedFormData?.invoiceTypeId;
                      const invoiceType = invoiceTypes?.find((it) => it.id === invoiceTypeId);
                      const {data: schoolYear} = useGetOne(entity.SCHOOL_YEAR.NAME, {id: formData?.schoolYearId ?? 0});
                      const listPeriode = generateMonthYear(schoolYear?.startDate, schoolYear?.endDate);
                      const invoicePeriode = scopedFormData?.invoicePeriode ? listPeriode : listPeriode.filter((it) => !invoicePeriodeExists?.includes(it.id));

                      return (
                        <React.Fragment>
                          <InvoiceType formData={formData} scopedFormData={scopedFormData} getSource={getSource} />
                          <AutocompleteInput
                            label="resources.school-year-students.fields.invoicePeriode"
                            sx={{ flex: 1, mr: { xs: 0, sm: "0.5em" } }}
                            source={getSource("invoicePeriode")}
                            choices={invoicePeriode}
                            validate={validateInvoiceType(invoiceType?.isMonthly)}
                            optionText="name"
                            optionValue="id"
                          />
                          <TextInput
                            label="resources.school-year-students.fields.invoiceAmount"
                            sx={{ flex: 1, mr: { xs: 0, sm: "0.5em" } }}
                            source={getSource("invoiceAmount")}
                            validate={required()}
                            format={format}
                            parse={parse}
                            defaultValue={0}
                          />
                          <TextInput
                            type="hidden"
                            source={getSource("code")}
                            sx={{ display: "none" }}
                          />
                          <TextInput
                            type="hidden"
                            source={getSource("invoiceTypeName")}
                            defaultValue={invoiceType?.name}
                            sx={{ display: "none" }}
                          />
                          <TextInput
                            label="resources.school-year-students.fields.invoiceBalance"
                            sx={{ flex: 1, mr: { xs: 0, sm: "0.5em" } }}
                            source={getSource("invoiceBalance")}
                            format={format}
                            parse={parse}
                            defaultValue={0}
                          />
                        </React.Fragment>
                      )
                    }}
                  </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
            </Box>
          }
        </SodikSimpleForm>
      </Grid>
    </Grid>
  );
};

export default SchoolYearStudentForm;
