import {
  Datagrid,
  SimpleList,
  TextField,
  EditButton,
  FunctionField,
} from "react-admin";
import { useMediaQuery } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import SodikInfiniteList from "@src/component/SodikInfiniteList";
import entity from "@src/entityResource";
import { listRowSx } from "@src/themes/componentStyle";

const SchoolYearStudentList = () => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  return (
    <SodikInfiniteList
      filterLabel="resources.school-year-students.filters.search"
      uploadUrl={entity.SCHOOL_YEAR_STUDENT.UPLOAD_URL}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.student.name}
          secondaryText={(record: any) => (
            <>
              {record?.schoolYear?.name}
              <br />
              {record?.stage?.name}
              <br />
              {record?.grade?.alphanumeric}.{record?.grade?.sub} - {record?.grade?.name}
            </>
          )}
          rightAvatar={(record) =>
            record.isBlacklisted === true ? (
              <DoDisturbOnIcon />
            ) : <CheckIcon />
          }
          linkType={(record) => (record.canEdit ? "edit" : "show")}
          rowSx={listRowSx}
        />
      ) : (
        <Datagrid>
          <TextField source="schoolYear.name" />
          <TextField source="stage.name" />
          <FunctionField
            label="resources.school-year-students.fields.gradeCode"
            render={(record: any) => `${record.grade.alphanumeric}.${record.grade.sub}`
            }
          />
          <TextField source="grade.name" />
          <TextField source="student.name" />
          <FunctionField
            label="resources.school-year-students.fields.isBlacklisted"
            render={(record: any) =>
              record.isBlacklisted === true ? <CheckIcon /> : null
            }
          />
          <EditButton />
        </Datagrid>
      )}
    </SodikInfiniteList>
  );
};

export default SchoolYearStudentList;
