import Papa from "papaparse";

interface ParsedCsvResult {
  data: any[];
  missingHeaders?: string[];
  errors: Papa.ParseError[];
  meta: Papa.ParseMeta;
}

export const parseCsv = (
  file: File,
  csvHeaders: string[]
): Promise<ParsedCsvResult> => {
  return new Promise((resolve) => {
    Papa.parse(file, {
      complete: (result) => {
        const validatedResult: ParsedCsvResult = { ...result };
        if (result.meta && result.meta.fields) {
          const missingHeaders = csvHeaders.filter(
            (header) => !result.meta.fields?.includes(header)
          );
          if (missingHeaders.length > 0) {
            validatedResult.missingHeaders = missingHeaders;
            validatedResult.errors = [
              {
                type: "FieldMismatch",
                code: "TooFewFields",
                message: `CSV file is missing expected headers: ${missingHeaders.join(
                  ", "
                )}`,
              },
            ];
          }
        } else {
          validatedResult.errors = [
            {
              type: "FieldMismatch",
              code: "TooFewFields",
              message: "CSV file does not contain headers",
            },
          ];
        }
        resolve(validatedResult);
      },
      header: true, // Assuming the first row contains headers
      dynamicTyping: false, // Convert numeric and boolean strings to their types
      skipEmptyLines: true, // Skip empty lines
    });
  });
};
