import React from "react";
import PropTypes from "prop-types";
import {
  Form,
  required,
  TextInput,
  useDataProvider,
  useTranslate,
  useNotify,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  Box,
  Card,
  CardActions,
  CircularProgress,
  Divider
} from "@mui/material";
import LockResetIcon from '@mui/icons-material/LockReset';
import entity from "@src/entityResource";

const ResetPassword = () => {
  const dataProvider = useDataProvider();
  const navigate = useNavigate();
  const translate = useTranslate();
  const notify = useNotify();
  const [loading, setLoading] = React.useState(false);
  const [identity, setIdentity] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [rePassword, setRePassword] = React.useState('');
  const [code, setCode] = React.useState('');

  const onCodeRequest = () => {
    setLoading(true);
    dataProvider
      .create(entity.IDENTITY_VERIFICATION.OTP, { data: { identity } })
      .then((result) => {
        notify("resources.reset-password.fields.success", {type: "info"});
        setLoading(false);
      })
      .catch((result) => {
        setLoading(false);
        notify("resources.reset-password.fields.error", { type: "error" });
      });
  };

  const handleSubmit = () => {
    setLoading(true);
    const data = {
      identity,
      password,
      code,
    };
    dataProvider
      .create(entity.IDENTITY_VERIFICATION.RESET_PASSWORD, {data: { ...data }})
      .then((result) => {
        notify("resources.reset-password.fields.changeSuccess", { type: "info" });
        setLoading(false);
        setCode('');
        setPassword('');
        setRePassword('');
        setIdentity('');
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      })
      .catch((result) => {
        setLoading(false);
        notify("resources.reset-password.fields.changeError", { type: "error" });
      });
  }

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          alignItems: "center",
          justifyContent: "flex-start",
          //background: 'url(https://source.unsplash.com/featured/1600x900)',
          //backgroundRepeat: 'no-repeat',
          //backgroundSize: 'cover',
        }}
      >
        <Card sx={{ minWidth: 300, marginTop: "6em" }}>
          <Box
            sx={{
              margin: "1em",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Avatar sx={{ bgcolor: "secondary.main" }}>
              <LockResetIcon />
            </Avatar>
          </Box>
          <Box
            sx={{
              marginTop: "1em",
              display: "flex",
              justifyContent: "center",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            Reset Kata Sandi
          </Box>
          <Box sx={{ padding: "0 1em 1em 1em" }}>
            <Box display="flex" flexDirection="column" marginBottom="1em">
              <Box flex={1}>
                <TextInput
                  autoFocus
                  source="identity"
                  label={translate("resources.reset-password.fields.identity")}
                  disabled={loading}
                  validate={!identity ? required() : undefined}
                  value={identity}
                  onChange={(e) => setIdentity(e.target.value)}
                  fullWidth
                />
              </Box>
              <Box flex={1}>
                <Button variant="outlined" color="error" onClick={onCodeRequest}>Klik untuk mendapatkan kode verifikasi</Button>
              </Box>
            </Box>
            <Divider />
            <Box display="flex" flexDirection="column" marginTop="1em">
              <Box flex={1}><TextInput
                source="pass"
                label={translate("resources.reset-password.fields.pass")}
                type="password"
                disabled={loading}
                validate={!password ? required() : undefined}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
              /></Box>
              <Box flex={1}><TextInput
                source="passConf"
                label={translate("resources.reset-password.fields.passConf")}
                type="password"
                disabled={loading}
                validate={!rePassword ? required() : undefined}
                value={rePassword}
                onChange={(e) => setRePassword(e.target.value)}
                fullWidth
              /></Box>
              <Box flex={1}><TextInput
                source="code"
                label={translate("resources.reset-password.fields.code")}
                disabled={loading}
                validate={!code ? required() : undefined}
                value={code}
                onChange={(e) => setCode(e.target.value)}
                fullWidth
              /></Box>
            </Box>
          </Box>
          <CardActions sx={{ padding: "0 1em 1em 1em" }}>
            <Button
              variant="text"
              type="button"
              color="primary"
              disabled={loading}
              fullWidth
              onClick={() => navigate("/login") }
            >
              {translate("resources.reset-password.fields.login")}
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="error"
              disabled={loading}
              fullWidth
            >
              {translate("resources.reset-password.fields.reset")}
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Form>
  );
};

ResetPassword.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

export default ResetPassword;
