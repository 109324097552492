import {
  Datagrid,
  TextField,
  FunctionField,
  EditButton,
  SimpleList,
} from "react-admin";
import { useMediaQuery } from "@mui/material";
import { listRowSx } from "@src/themes/componentStyle";
import SodikInfiniteList from "@src/component/SodikInfiniteList";
import entity from "@src/entityResource";

const GradeList = () => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  return (
    <SodikInfiniteList
      filterLabel="resources.grades.filters.search"
      uploadUrl={entity.GRADE.UPLOAD_URL}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => `${record.alphanumeric}.${record.sub} - ${record.name}`}
          secondaryText={(record: any) => record.stage.name}
          linkType={(record) => (record.canEdit ? "edit" : "show")}
          leftAvatar={(record) => record.code}
          rowSx={listRowSx}
        />
      ) : (
        <Datagrid>
          <TextField source="stage.name" />
          <TextField source="code" />
          <FunctionField
            label="resources.grades.fields.classCode"
            render={(record: any) => `${record.alphanumeric}.${record.sub}`}
          />
          <TextField source="name" />
          <EditButton />
        </Datagrid>
      )}
    </SodikInfiniteList>
  );
};
export default GradeList;
