import * as React from "react";
import { TopToolbar, CreateButton, ExportButton, Button } from "react-admin";
import { Link as RouterLink } from "react-router-dom";
import UploadFileIcon from "@mui/icons-material/UploadFile";

interface IUploadButtonProps {
  to: string;
}

interface ISodikTopToolbarProps extends IUploadButtonProps {
  create: boolean;
}

const UploadButton: React.FC<IUploadButtonProps> = ({ to }) => {
  return (
    <Button
      component={RouterLink}
      to={to}
      label="resources.components.uploadLabel"
      startIcon={<UploadFileIcon />}
    />
  );
};

const SodikTopToolbar: React.FC<ISodikTopToolbarProps> = ({ to, create }) => {
  return (
    <TopToolbar>
      {to && <UploadButton to={to} />}
      {create && <CreateButton label="resources.components.createLabel" />}
      <ExportButton />
    </TopToolbar>
  );
};

export default SodikTopToolbar;
