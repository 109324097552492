import * as React from "react";
import { Title } from "react-admin";
import CsvUpload from "@src/component/SodikFileInput";
import request from "@src/dataRequest";
import entity from "@src/entityResource";

const headers = ["kode_tahun_ajaran", "kode_sekolah", "nama_tagihan", "jumlah", "bulanan", "tahunan"];

interface ICsvData {
  kode_tahun_ajaran: string;
  kode_sekolah: string;
  nama_tagihan: string;
  jumlah: string;
  bulanan: string;
  tahunan: string;
}

const verifyData = async (csvData: ICsvData[]) => {
  const schoolYearData = await request.getList({
    key: "kode_tahun_ajaran",
    csv: csvData,
    apiResource: entity.SCHOOL_YEAR.NAME,
    entityProp: "id",
  });

  const stageData = await request.getList({
    key: "kode_sekolah",
    csv: csvData,
    apiResource: entity.STAGE.NAME,
    entityProp: "id",
  });

  return csvData.map((row: ICsvData) => {
    const { kode_tahun_ajaran, kode_sekolah, nama_tagihan, jumlah, bulanan, tahunan } = row;
    const schoolYear = schoolYearData
      ? schoolYearData.find((d: any) => d.id === +kode_tahun_ajaran)
      : undefined;
    const stage = stageData ? stageData.find((d: any) => d.id === +kode_sekolah) : undefined;

    return {
      ...(schoolYear && { schoolYearId: schoolYear.id }),
      ...(stage && { stageId: stage.id }),
      ...(nama_tagihan && { name: nama_tagihan }),
      ...(jumlah && { amount: jumlah }),
      ...(bulanan && { isMonthly: bulanan }),
      ...(tahunan && { isYearly: tahunan }),
    };
  });
};

const InvoiceTypeUpload = () => {
  return (
    <React.Fragment>
      <Title title="invoiceTypeUpload.title" />
      <CsvUpload
        headers={headers}
        fileInputLabel="invoiceTypeUpload.fileInputLabel"
        apiResource={entity.INVOICE_TYPE.API_UPLOAD}
        resource={entity.INVOICE_TYPE.NAME}
        verifyData={verifyData}
      />
    </React.Fragment>
  );
};

export default InvoiceTypeUpload;
