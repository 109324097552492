import React from 'react';
import {
    AutocompleteInput,
    useGetList,
    required,
  } from "react-admin";
import entity from '@src/entityResource';

/**
 * @description SchoolYear component. Sy stand for SchoolYear
 * @returns 
 */
const Sy = () => {
    const { data: schoolYears } = useGetList(entity.SCHOOL_YEAR.NAME, {filter: { isCurrent: true }});
  
    return (
      <React.Fragment>
        <AutocompleteInput
          source="schoolYearId"
          choices={schoolYears}
          optionText="name"
          optionValue="id"
          validate={required()}
          fullWidth
        />
      </React.Fragment>
    );
}

const SchoolYear = () => <Sy />

export default SchoolYear;