import {
  Datagrid,
  SimpleList,
  TextField,
  EditButton,
  FunctionField
} from "react-admin";
import { useMediaQuery } from "@mui/material";
import SodikInfiniteList from "@src/component/SodikInfiniteList";
import entity from "@src/entityResource";
import { listRowSx } from "@src/themes/componentStyle";

const SchoolYearEmployeeList = () => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  return (
    <SodikInfiniteList
      filterLabel="resources.school-year-employees.filters.search"
      uploadUrl={entity.SCHOOL_YEAR_EMPLOYEE.UPLOAD_URL}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.employee.name}
          secondaryText={(record: any) => (
            <>
              <div style={{display: 'flex'}}>
                <div>{record?.stage?.name}</div>
                <div>&nbsp;-&nbsp;</div>
                <div><strong>{record?.grade?.alphanumeric}.{record?.grade?.sub} - {record?.grade?.name}</strong></div>
              </div>
              <div style={{display: 'flex'}}>
                <div>{record?.schoolYear?.name}</div>
              </div>
            </>
          )}
          linkType={(record) => (record.canEdit ? "edit" : "show")}
          rowSx={listRowSx}
        />
      ) : (
        <Datagrid>
          <TextField source="schoolYear.name" />
          <TextField source="stage.name" />
          <FunctionField
            label="resources.school-year-employees.fields.gradeCode"
            render={(record: any) => `${record.grade.alphanumeric}.${record.grade.sub}`
            }
          />
          <TextField source="grade.name" />
          <TextField source="employee.name" />
          <EditButton />
        </Datagrid>
      )}
    </SodikInfiniteList>
  );
};

export default SchoolYearEmployeeList;
