import env from "@src/environment";

const numberHelper = {
    /**
     * @description Parse value of string to number
     * @param v 
     * @returns 
    */
    parse: (s: string) => isNaN(parseInt(s.replace(/[^0-9]/g, ""))) ? 0 : parseInt(s.replace(/[^0-9]/g, "")),

    /**
     * @description Format number to locale
     * @param v 
     * @returns 
    */
    format: (n: number) => Intl.NumberFormat(env.localFormat).format(n)
}

export const { parse, format } = numberHelper;
