const dateFormat = {
  displayFormat: (date: string, locale: string) => {
    return new Date(date).toLocaleDateString(locale, {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
  },

  longDisplayFormat: (date: string, locale: string) => {
    return new Date(date).toLocaleDateString(locale, {
      year: "numeric",
      month: "short",
      day: "2-digit",
      weekday: 'long',
    });
  },

  longDisplayFormatWithTime: (date: string, locale: string) => {
    return new Date(date).toLocaleDateString(locale, {
      year: "numeric",
      month: "short",
      day: "2-digit",
      weekday: 'long',
      hour: '2-digit',
      minute: '2-digit',
    });
  },

  dataFormat: (date: string) => {
    return new Date(date).toISOString();
  },

  yyyyMMdd: (date: string) => {
    return new Date(date).toISOString().slice(0, 10).replace(/-/g, "");
  },
};

export const { displayFormat, longDisplayFormat, longDisplayFormatWithTime, dataFormat, yyyyMMdd } = dateFormat;
