import * as React from "react";
import { InfiniteList, ListProps, TextInput } from "react-admin";
import InfiniteLoad from "./InfiniteLoad";
import SodikTopToolbar from "./SodikTopToolbar";

interface ISodikInfiniteList extends ListProps {
  filterLabel: string;
  uploadUrl: string;
  create?: boolean;
}

const SodikInfiniteList: React.FC<ISodikInfiniteList> = ({
  children,
  filterLabel,
  uploadUrl: uploadUrl,
  create = true,
  //...rest
}) => {
  const textFilter = [
    <TextInput
      source="q"
      label={filterLabel}
      sx={{ debounce: 3000 }}
      alwaysOn
      resettable={true}
    />,
  ];

  return (
    <InfiniteList
      empty={false}
      pagination={<InfiniteLoad />}
      perPage={10}
      filters={textFilter}
      actions={<SodikTopToolbar to={uploadUrl} create={create} />}
      exporter={false}
    >
      {children}
    </InfiniteList>
  );
};

export default SodikInfiniteList;
