import React from "react";
import {
    AutocompleteInput,
    required,
    useGetList,
} from "react-admin";

const St = ({ schoolYearId, isRequired, path }: { schoolYearId?: number, isRequired: boolean, path: string}) => {
    const { data: students } = useGetList(path, {filter: { q:'', schoolYearId: schoolYearId }});

    return (
      <React.Fragment>
        <AutocompleteInput
          source="studentId"
          choices={students}
          optionText="name"
          optionValue="id"
          validate={isRequired ? required() : undefined}
        />
      </React.Fragment>
    )
  }

  const Student = ({schoolYearId, isRequired, path}: {schoolYearId?: number, isRequired: boolean, path: string}) => <St schoolYearId={schoolYearId} isRequired={isRequired} path={path} />
  export default Student;
