import * as React from "react";
import { Title } from "react-admin";
import CsvUpload from "@src/component/SodikFileInput";
import { dataFormat } from "@src/helper/dateFormat";
import entity from "@src/entityResource";

interface ICsvData {
  nama: string;
  tanggal_mulai: string;
  tanggal_akhir: string;
  aktif: number;
}

const csvHeaders = ["nama", "tanggal_mulai", "tanggal_akhir"];

const SchoolYearUpload = () => {
  const verifyData = (data: ICsvData[]) => {
    return data.map((d: ICsvData) => {
      const { nama, tanggal_mulai, tanggal_akhir, aktif } = d;
      return {
        ...(nama && { name: nama }),
        ...(tanggal_mulai && { startDate: dataFormat(tanggal_mulai) }),
        ...(tanggal_akhir && { endDate: dataFormat(tanggal_akhir) }),
        ...(aktif && { isCurrent: aktif }),
      };
    });
  };

  return (
    <React.Fragment>
      <Title title="schoolYearUpload.title" />
      <CsvUpload
        headers={csvHeaders}
        fileInputLabel="schoolYearUpload.fileInputLabel"
        apiResource={entity.SCHOOL_YEAR.API_UPLOAD}
        resource={entity.SCHOOL_YEAR.NAME}
        verifyData={verifyData}
      />
    </React.Fragment>
  );
};

export default SchoolYearUpload;
