import { Datagrid, TextField, EditButton, SimpleList, FunctionField } from "react-admin";
import CheckIcon from "@mui/icons-material/Check";
import { useMediaQuery } from "@mui/material";
import { listRowSx } from "@src/themes/componentStyle";
import SodikInfiniteList from "@src/component/SodikInfiniteList";
import { longDisplayFormatWithTime } from "@src/helper/dateFormat";


const UserList = () => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  return (
    <SodikInfiniteList
      filterLabel="resources.users.filters.search"
      uploadUrl=""
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => (
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <div style={{flex: 1, textTransform: 'uppercase'}}>{record?.username}</div>
              <div style={{flex: 1}}>{record?.student?.schoolYearStudent[0] ? `${record?.student?.schoolYearStudent[0]?.stage?.name} - ${record?.student?.schoolYearStudent[0]?.grade?.name} ${record?.student?.schoolYearStudent[0].grade?.alphanumeric} ${record?.student?.schoolYearStudent[0]?.grade.sub}`: <></>}</div>
            </div>
          )}
          secondaryText={(record) => (
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <div style={{flex:1}}>{record?.student?.communicationEmail || record?.employee?.communicationEmail}</div>
              <div style={{flex:1}}>{record?.student?.communicationPhoneNo || record?.employee?.communicationPhoneNo}</div>
              <div style={{flex: 1}}>{record.lastLoginAt ? longDisplayFormatWithTime(record.lastLoginAt, "id-ID") : "-"}</div>
            </div>
          )}
          rightAvatar={(record) =>
            record.isActive === true ? (
              <CheckIcon />
            ) : (
              <></>
            )
          }
          linkType={(record) => (record.canEdit ? "edit" : "show")}
          rowSx={listRowSx}
        />
      ) : (
        <Datagrid>
          <FunctionField
            label="resources.users.fields.name"
            render={(record: any) =>
              record.studentId ? (<TextField source="student.name" />) : record.employeeId ? (<TextField source="employee.name" />) : (<TextField source="name" />)
            }
          />
          <TextField source="username" />
          <TextField source="role.name" />
          <FunctionField
            label="resources.users.fields.currentStudent"
            render={(record: any) =>
              record?.student?.schoolYearStudent[0] ? `${record?.student?.schoolYearStudent[0]?.stage?.name} - ${record?.student?.schoolYearStudent[0]?.grade?.name} ${record?.student?.schoolYearStudent[0].grade?.alphanumeric}.${record?.student?.schoolYearStudent[0]?.grade.sub}` : ""
            }
          />
          <FunctionField
            label="resources.users.fields.email"
            render={(record: any) =>
              record?.student?.communicationEmail ? record?.student?.communicationEmail : record?.employee?.communicationEmail ? record?.employee?.communicationEmail : ""
            }
          />
          <FunctionField
            label="resources.users.fields.phoneNo"
            render={(record: any) =>
              record?.student?.communicationPhoneNo ? record?.student?.communicationPhoneNo : record?.employee?.communicationPhoneNo ? record?.employee?.communicationPhoneNo : ""
            }
          />
          <FunctionField
            label="resources.users.fields.isActive"
            render={(record: any) =>
              record.isActive === true ? <CheckIcon /> : null
            }
          />
          <FunctionField
            label="resources.users.fields.lastLogin"
            render={(record: any) =>
              record.lastLoginAt ? longDisplayFormatWithTime(record.lastLoginAt, "id-ID") : "-"
            }
          />
          <EditButton />
        </Datagrid>
      )}
    </SodikInfiniteList>
  );
};

export default UserList;
