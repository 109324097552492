import { Datagrid, SimpleList, TextField, FunctionField, useTranslate } from "react-admin";
import { useMediaQuery } from "@mui/material";
import SodikInfiniteList from "@src/component/SodikInfiniteList";
import entity from "@src/entityResource";
import { listRowSx } from "@src/themes/componentStyle";
import env from "@src/environment";

const SchoolYearInvoiceList = () => {
  const intl = env.localFormat;
  const translate = useTranslate();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  return (
    <SodikInfiniteList
      filterLabel="resources.school-year-invoices.filters.search"
      uploadUrl={entity.SCHOOL_YEAR_INVOICE.UPLOAD_URL}
      create={false}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record?.schoolYearStudent?.student?.name}
          secondaryText={(record: any) => (
            <>
              <div style={{display: 'flex'}}>
                <div>{record?.schoolYearStudent?.schoolYear?.name}</div>
              </div>
              <div style={{display: 'flex'}}>
                <div>{record?.schoolYearStudent?.stage?.name}</div>
                <div>&nbsp;-&nbsp;</div>
                <div>{record?.schoolYearStudent?.grade?.alphanumeric}.{record?.schoolYearStudent?.grade?.sub} - {record?.schoolYearStudent?.grade?.name}</div>
              </div>
              <hr />
              <div style={{display: 'flex'}}>
                <div style={{flex: 3, display: 'flex', justifyContent: 'flex-end'}}>
                  <div><strong>{record?.invoiceType?.name}</strong></div>
                  <div>&nbsp;{record?.schoolYearStudent?.schoolYear?.name}</div>
                </div>
                <div style={{flex: 1, textAlign: 'right'}}><strong>{Intl.NumberFormat(intl).format(record?.invoiceAmount)}</strong></div>
              </div>
              <div style={{display: 'flex'}}>
                <div style={{flex: 3, textAlign: 'right'}}>{translate("resources.school-year-invoices.list.balance")}&nbsp;</div>
                <div style={{flex: 1, textAlign: 'right'}}><strong>{Intl.NumberFormat(intl).format(record?.invoiceBalance)}</strong></div>
              </div>
            </>
          )}
          linkType={(record) => (record.canEdit ? "edit" : "show")}
          rowSx={listRowSx}
        />
      ) : (
        <Datagrid>
          <TextField source="schoolYearStudent.schoolYear.name" />
          <TextField source="schoolYearStudent.stage.name" />
          <TextField source="invoiceType.name" />
          <TextField source="invoicePeriode" />
          <TextField source="schoolYearStudent.grade.name" />
          <TextField source="schoolYearStudent.student.name" />
          <FunctionField
            source="invoiceAmount"
            textAlign="right"
            render={(record: any) =>
              Intl.NumberFormat(intl).format(record.invoiceAmount)
            }
          />
          <FunctionField
            source="invoiceBalance"
            textAlign="right"
            render={(record: any) =>
              Intl.NumberFormat(intl).format(record.invoiceBalance)
            }
          />

        </Datagrid>
      )}
    </SodikInfiniteList>
  );
};

export default SchoolYearInvoiceList;
