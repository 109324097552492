import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import list from "./SavingList";
import create from "./SavingCreate";
import show from "./SavingShow";

export default {
  list: list,
  create: create,
  show: show,
  icon: AccountBalanceWalletIcon,
};
