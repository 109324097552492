import * as React from "react";
import { Title } from "react-admin";
import CsvUpload from "@src/component/SodikFileInput";
import entity from "@src/entityResource";

interface ICsvData {
  nik: string;
  nis: string;
  nama_siswa: string;
  asal_sekolah: string;
  avatar: string;
  no_kk: string;
  no_tlp: string;
  email: string;
  nik_ayah: string;
  nama_ayah: string;
  no_tlp_ayah: string;
  email_ayah: string;
  nik_ibu: string;
  nama_ibu: string;
  no_tlp_ibu: string;
  email_ibu: string;
  alamat: string;
  longlat: string;
}

const headers = [
  "nik",
  "nis",
  "nis",
  "nama_siswa",
  "asal_sekolah",
  "avatar",
  "no_kk",
  "no_tlp",
  "email",
  "nik_ayah",
  "nama_ayah",
  "no_tlp_ayah",
  "email_ayah",
  "nik_ibu",
  "nama_ibu",
  "no_tlp_ibu",
  "email_ibu",
  "alamat",
  "longlat",
];

const verifyData = async (csvData: ICsvData[]) => {
  return csvData.map((row: ICsvData) => {
    const {
      nik,
      nis,
      nama_siswa,
      asal_sekolah,
      avatar,
      no_kk,
      no_tlp,
      email,
      nik_ayah,
      nama_ayah,
      no_tlp_ayah,
      email_ayah,
      nik_ibu,
      nama_ibu,
      no_tlp_ibu,
      email_ibu,
      alamat,
      longlat,
    } = row;
    return {
      ...(nik && { nik: nik }),
      ...(nis && { nis: nis }),
      ...(nama_siswa && { name: nama_siswa }),
      ...(asal_sekolah && { previousSchool: asal_sekolah }),
      ...(avatar && { avatar }),
      ...(no_kk && { familyCardNo: no_kk }),
      ...(no_tlp && { communicationPhoneNo: no_tlp }),
      ...(email && { communicationEmail: email }),
      ...(nik_ayah && { fatherIdNo: nik_ayah }),
      ...(nama_ayah && { fatherName: nama_ayah }),
      ...(no_tlp_ayah && { fatherPhoneNo: no_tlp_ayah }),
      ...(email_ayah && { fatherEmail: email_ayah }),
      ...(nik_ibu && { motherIdNo: nik_ibu }),
      ...(nama_ibu && { motherName: nama_ibu }),
      ...(no_tlp_ibu && { motherPhoneNo: no_tlp_ibu }),
      ...(email_ibu && { motherEmail: email_ibu }),
      ...(alamat && { address: alamat }),
      ...(longlat && { longlat: longlat }),
    };
  });
};

const StudentUpload = () => {
  return (
    <React.Fragment>
      <Title title="studentUpload.title" />
      <CsvUpload
        headers={headers}
        fileInputLabel="studentUpload.fileInputLabel"
        apiResource={entity.STUDENT.API_UPLOAD}
        resource={entity.STUDENT.NAME}
        verifyData={verifyData}
      />
    </React.Fragment>
  );
};

export default StudentUpload;
