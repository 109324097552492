import * as React from "react";
import { Title } from "react-admin";
import CsvUpload from "@src/component/SodikFileInput";
import entity from "@src/entityResource";

interface ICsvData {
  nik: string;
  nama: string;
  nama_panggilan: string;
  no_tlp: string;
  email: string;
  alamat: string;
  longlat: string;
}

const headers = [
  "nik",
  "nama",
  "nama_panggilan",
  "no_tlp",
  "email",
  "alamat",
  "longlat",
];

const verifyData = async (csvData: ICsvData[]) => {
  return csvData.map((row: ICsvData) => {
    const {
      nik,
      nama,
      nama_panggilan,
      no_tlp,
      email,
      alamat,
      longlat,
    } = row;
    return {
      ...(nik && { nik: nik }),
      ...(nama && { name: nama }),
      ...(nama_panggilan && { nickName: nama_panggilan }),
      ...(no_tlp && { communicationPhoneNo: no_tlp }),
      ...(email && { communicationEmail: email }),
      ...(alamat && { address: alamat }),
      ...(longlat && { longlat: longlat }),
    };
  });
};

const EmployeeUpload = () => {
  return (
    <React.Fragment>
      <Title title="employeeUpload.title" />
      <CsvUpload
        headers={headers}
        fileInputLabel="employeeUpload.fileInputLabel"
        apiResource={entity.EMPLOYEE.API_UPLOAD}
        resource={entity.EMPLOYEE.NAME}
        verifyData={verifyData}
      />
    </React.Fragment>
  );
};

export default EmployeeUpload;
