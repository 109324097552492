interface IEnvironment {
  apiUrl: string;
  passwordHash: string;
  dateInputFormat: string;
  localFormat: string;
}

const environment: IEnvironment = {
  apiUrl: import.meta.env.VITE_JSON_SERVER || "",
  passwordHash: import.meta.env.VITE_SALT,
  dateInputFormat: "MM/DD/YYYY",
  localFormat: import.meta.env.VITE_INTL_NUMBER || "en-US",
};

export default environment;
