import React from "react";
import {
    Datagrid,
    FunctionField,
    Form,
    SimpleList,
    ShowButton,
    TextField,
    useCreate,
    useRecordContext,
    useNotify,
    useRefresh,
    useTranslate
  } from "react-admin";
  import { 
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    TextField as MUITextField,
    Select,
    SelectChangeEvent,
    useMediaQuery 
  } from "@mui/material";
  import PriceChangeIcon from '@mui/icons-material/PriceChange';
  import SodikInfiniteList from "@src/component/SodikInfiniteList";
  import TextFieldNumber from "@src/component/TextFieldNumber";
  import {
    boxContainerDisplay,
    boxContainerFieldsetSx,
    boxItemMr,
    listRowSx
  } from "@src/themes/componentStyle";
  import env from "@src/environment";
  import ent from "@src/entityResource";

  const savingTypeOpt = {
    credit: "credit",
    debit: "debit",
  }

  type Saving = {
    savingId: number;
    studentId: number;
    balance: number;
    name: string;
  }

  const OpenModalButton = ({ onOpen, label } : {onOpen: (saving:Saving) => void; label: string}) => {
    const record = useRecordContext();
  
    const handleClickOpen = () => {
      onOpen({
        savingId: +record.id,
        studentId: +record.studentId,
        balance: +record.balance,
        name: record.student.name,
      });
    };
  
    return (
      <Button variant="text" color="primary" onClick={handleClickOpen} startIcon={<PriceChangeIcon />}>
        {label}
      </Button>
    );
  };
  
  const SavingList = () => {
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
    const intl = env.localFormat;
    const notify = useNotify();
    const refresh = useRefresh();
    const translate = useTranslate();
    const [create, { isLoading, error }] = useCreate();
    const [open, setOpen] = React.useState(false);
    const [savingType, setSavingType] = React.useState<string>("");
    const [amount, setAmount] = React.useState<number>(0);
    const [amountError, setAmountError] = React.useState<boolean>(false);
    const [description, setDescription] = React.useState<string>("");
    const [saving, setSaving] = React.useState<Saving>();

    const handleOpen = (saving:Saving) => {
      setSaving(saving);
      setOpen(true);
    };

    const handleClose = () => {
      setSaving(undefined);
      setSavingType("");
      setAmount(0);
      setAmountError(false);
      setDescription("");
      setOpen(false);
    };

    const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = +event.target.value;
      setAmount(value);

      if (value < 1) {
        setAmountError(true);
      } else {
        setAmountError(false);
      }
    };

    const handleSavingTypeChange = (event: SelectChangeEvent) => {
      setSavingType(event.target.value);
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setDescription(event.target.value);
    };

    const postSave = async () => {
      try{
        if (amount < 1) {
          setAmountError(true);
          return;
        }

        if (savingType === savingTypeOpt.debit && amount > (saving?.balance ?? 0)) {
          notify("resources.savings.notifications.insuficient", {type: "warning" });
          return;
        }

        const endpoint = savingType === savingTypeOpt.credit ? ent.SAVING.DEPOSIT : ent.SAVING.WITHDRAW;
        await create(endpoint, {
          data: {
            savingId: saving?.savingId,
            studentId: saving?.studentId,
            studentName: saving?.name,
            savingType,
            amount,
            description,
          }
        });

        notify("resources.savings.notifications.saved", {type: "success"});
        setTimeout(() => {
          handleClose();
          refresh();
        }, 2000)

      } catch (error) {
      }
    }
  
    return (
      <SodikInfiniteList
        filterLabel="resources.savings.filters.search"
        uploadUrl={""}
      >
        {isSmall ? (
          <SimpleList
            primaryText={(record) => record?.student?.name}
            secondaryText={(record: any) => (
              <>
                <div style={{display: 'flex'}}>
                  <div>{record?.student?.schoolYearStudent[0]?.stage?.name}</div>
                  <div>&nbsp;-&nbsp;</div>
                  <div>{record?.student?.schoolYearStudent[0]?.grade?.alphanumeric}.{record?.student?.schoolYearStudent[0]?.grade?.sub} - {record?.student?.schoolYearStudent[0]?.grade?.name}</div>
                </div>
                <hr />
                <div style={{display: 'flex'}}>
                  <div style={{flex: 2, textAlign: 'right'}}>{translate("resources.savings.list.balance")}</div>
                  <div style={{flex: 1, textAlign: 'right'}}><strong>{Intl.NumberFormat(intl).format(record?.balance)}</strong></div>
                </div>
              </>
            )}
            // secondaryText={(record) => record.balance}
            linkType={(record) => (record.canEdit ? "edit" : "show")}
            rowSx={listRowSx}
          />
        ) : (
          <>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>{translate("resources.savings.fields.title")}</DialogTitle>
              <Form onSubmit={postSave} sanitizeEmptyValues>
                <DialogContent>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <MUITextField id="saving-id" type="hidden" value={saving?.savingId} />
                      <MUITextField id="student-id" type="hidden" value={saving?.studentId} />
                      <Box display={boxContainerDisplay} sx={boxContainerFieldsetSx}>
                        <Box flex={1} mr={boxItemMr}>
                          <FormControl fullWidth>
                            <InputLabel id="saving-type-label">{translate("resources.savings.fields.savingType")}</InputLabel>
                            <Select
                              id="saving-type"
                              label-id="saving-type-label" 
                              value={savingType}
                              onChange={handleSavingTypeChange} 
                              fullWidth
                              required
                            >
                              <MenuItem value={savingTypeOpt.credit}>{translate("resources.savings.fields.credit")}</MenuItem>
                              <MenuItem value={savingTypeOpt.debit}>{translate("resources.savings.fields.debit")}</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={boxContainerDisplay} sx={boxContainerFieldsetSx}>
                        <Box flex={2} mr={boxItemMr}>
                          <MUITextField
                            label={translate("resources.savings.fields.studentName")}
                            id="student-name"
                            value={saving?.name}
                            fullWidth
                            InputProps={{ readOnly: true }}
                          />
                        </Box>
                        <Box flex={1} mr={boxItemMr}>
                          <MUITextField 
                            id="balance"
                            label={translate("resources.savings.fields.balance")}
                            value={saving?.balance}
                            fullWidth
                            InputProps={{
                              inputComponent: TextFieldNumber,
                              readOnly: true,
                            }}
                          />  
                        </Box>
                      </Box>
                      <Box display={boxContainerDisplay} sx={boxContainerFieldsetSx}>
                        <Box flex={1} mr={boxItemMr}>
                          <MUITextField 
                            id="deposit"
                            label={translate("resources.savings.fields.amount")}
                            value={amount}
                            onChange={handleAmountChange} 
                            fullWidth
                            required
                            error={amountError}
                            InputProps={{
                              inputComponent: TextFieldNumber,
                            }}
                          />  
                        </Box>
                      </Box>
                      <Box display={boxContainerDisplay} sx={boxContainerFieldsetSx}>
                        <Box flex={1} mr={boxItemMr}>
                          <MUITextField
                            label={translate("resources.savings.fields.description")}
                            id="description"
                            value={description}
                            onChange={handleDescriptionChange}
                            fullWidth 
                            multiline={true}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button type="submit" color="primary">Save</Button>
                  <Button onClick={handleClose} color="primary">Close</Button>
                </DialogActions>
              </Form>
            </Dialog>
            <Datagrid>
              <TextField source="student.schoolYearStudent[0].stage.name" />
              <TextField source="student.name" />
              <FunctionField
                label="resources.savings.fields.gradeCode"
                render={(record: any) => `${record.student.schoolYearStudent[0].grade.alphanumeric}.${record.student.schoolYearStudent[0].grade.sub}`
                }
              />
              <FunctionField
                label="resources.savings.fields.grade"
                render={(record: any) => record.student.schoolYearStudent[0].grade.name
                }
              />
              <FunctionField
                source="balance"
                textAlign="right"
                render={(record: any) =>
                  Intl.NumberFormat(intl).format(record.balance)
                }
              />
              <>
                <ShowButton />
                <OpenModalButton onOpen={handleOpen} label={translate("resources.savings.fields.transaction")} />
              </>
            </Datagrid>
          </>
        )}
      </SodikInfiniteList>
    );
  };
  
  export default SavingList;
  